import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import {
  useAddCryptoBeneficiaryMutation,
  useFetchSupportedCoinsQuery,
  useUpdateCryptoBeneficiaryMutation,
} from "../../redux/services/beneficiariesApi";
import CustomDropdown from "../../utils/CustomDropdown";
import { showError, showSuccess } from "../../utils/Alert";
import WalletPinModal from "../settings/WalletPinModal";
import { CryptoBeneficiaryObj } from "../../redux/services/types";
import { useCheckWalletPinMutation } from "../../redux/services/walletApi";
import { usePostHog } from "posthog-js/react";

const CryptoBeneficiaryForm = ({
  closeModal,
  cryptoBeneficiaryToUpdate = null,
}: {
  cryptoBeneficiaryToUpdate?: CryptoBeneficiaryObj | null;
  closeModal: () => void;
}) => {
  const [formState, setFormState] = useState({
    title: "",
    coin_id: "",
    network: "",
    address: "",
    tag_id: "",
    description: "",
  });
  const posthog = usePostHog();
  const [showWalletPinModal, setShowWalletPinModal] = useState(false);
  const [showTagInput, setShowTagInput] = useState(false);
  const [supportedCryptos, setSupportedCryptos] = useState<any[]>([]);
  const [supportedNetworks, setSupportedNetworks] = useState<any[]>([]);
  const { data: fetchedSuppCoins, isSuccess: isFetchedSuppCoinsSuccess } =
    useFetchSupportedCoinsQuery();
  const [updateCryptoBeneficiary, { isLoading: isUpdateCryptoBenLoading }] =
    useUpdateCryptoBeneficiaryMutation();
  const [addCryptoBeneficiary, { isLoading: isAddCryptoBeneficiaryLoading }] =
    useAddCryptoBeneficiaryMutation();
  const [checkPin, { isLoading: isCheckPinLoading }] =
    useCheckWalletPinMutation();

  //   process fetched supported coins
  useEffect(() => {
    if (isFetchedSuppCoinsSuccess) {
      const newArray = fetchedSuppCoins?.map((crypto) => ({
        ...crypto,
        value: crypto.id,
        name: crypto.name.toUpperCase(),
        icon: crypto.logo_url,
      }));

      setSupportedCryptos(newArray);
      //   console.log("supp crypto", fetchedSuppCoins);
    }
  }, [isFetchedSuppCoinsSuccess, fetchedSuppCoins]);

  useEffect(() => {
    if (cryptoBeneficiaryToUpdate) {
      setFormState((prevState) => ({
        ...prevState,
        title: cryptoBeneficiaryToUpdate?.title || "",
        coin_id: cryptoBeneficiaryToUpdate?.coin_id || "",
        network: cryptoBeneficiaryToUpdate?.network || "",
        address: cryptoBeneficiaryToUpdate?.address || "",
        tag_id: cryptoBeneficiaryToUpdate?.tag_id || "",
        description: cryptoBeneficiaryToUpdate?.description || "",
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        title: "",
        coin_id: "",
        network: "",
        address: "",
        tag_id: "",
        description: "",
      }));
    }
  }, [cryptoBeneficiaryToUpdate]);

  useEffect(() => {
    const selectedCoin = supportedCryptos.filter(
      (val) => val.id === formState.coin_id
    );
    if (selectedCoin.length) {
      const networkArr = selectedCoin[0]?.networks?.map((val: any) => ({
        name: val,
        value: val,
      }));
      setSupportedNetworks(networkArr);
      //   console.log(networkArr);
    }
  }, [formState.coin_id, supportedCryptos]);

  // show/hide tag_id input
  useEffect(() => {
    const selectedCoin = supportedCryptos.filter(
      (val) => val.id === formState.coin_id
    );
    if (
      selectedCoin.length &&
      selectedCoin[0]?.name?.toLowerCase() === "ripple"
    ) {
      setShowTagInput(true);
    } else {
      setShowTagInput(false);
    }
  }, [formState.coin_id, supportedCryptos]);

  const handleInputs = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const openWalletPinModal = (e: any) => {
    e.preventDefault();
    if (
      formState.title === "" ||
      formState.coin_id === "" ||
      formState.network === "" ||
      formState.address === ""
    ) {
      showError("Required fields are missing");
      return;
    }

    if (cryptoBeneficiaryToUpdate) {
      handleSubmit();
    } else {
      setShowWalletPinModal(true);
    }
  };

  const handleSubmit = async (pin?: string, resetPin?: () => void) => {
    if (!cryptoBeneficiaryToUpdate) {
      if (!pin || pin?.length < 6) {
        showError("Please enter wallet pin");
        return;
      }
    }

    if (cryptoBeneficiaryToUpdate) {
      updateCryptoBeneficiary({
        id: cryptoBeneficiaryToUpdate?.id,
        data: formState,
      })
        .unwrap()
        .then(() => {
          showSuccess("Beneficiary updated");
          setFormState({
            ...formState,
            title: "",
            coin_id: "",
            network: "",
            address: "",
            tag_id: "",
            description: "",
          });
          closeModal();
        })
        .catch((err) => {
          console.log(err);
          showError(err?.message || err?.data?.message || "An error occurred");
        });
    } else {
      let isPinErr = true;
      await checkPin({ pin })
        .unwrap()
        .then((resp) => {
          if (resp?.success) {
            isPinErr = false;
          } else {
            showError(resp?.message || "Could not validate wallet pin");
          }
        })
        .catch((err) => {
          showError(err?.message || "Could not validate wallet pin");
          return;
        });

      if (!isPinErr) {
        addCryptoBeneficiary(formState)
          .unwrap()
          .then(() => {
            posthog?.capture("beneficiary_added", {
              beneficiaryType: "crypto",
            });
            showSuccess("Beneficiary added");
            setFormState({
              ...formState,
              title: "",
              coin_id: "",
              network: "",
              address: "",
              tag_id: "",
              description: "",
            });
            resetPin?.();
            setShowWalletPinModal(false);
            closeModal();
          })
          .catch((err) => {
            console.log(err);
            resetPin?.();
            showError(
              err?.message || err?.data?.message || "An error occurred"
            );
          });
      }
    }
  };

  return (
    <>
      <form>
        <FormControl display={"flex"} flexDirection={"column"} gap={5}>
          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Title
            </FormLabel>
            <Input
              type="text"
              focusBorderColor={"gray.300"}
              value={formState.title}
              name="title"
              onChange={handleInputs}
            />
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Select Coin
            </FormLabel>
            <CustomDropdown
              value={formState.coin_id}
              itemOnClick={(val) =>
                setFormState({ ...formState, coin_id: val })
              }
              dropdownItems={supportedCryptos}
              disabled={cryptoBeneficiaryToUpdate ? true : false}
            />
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Select Network
            </FormLabel>
            <CustomDropdown
              value={formState.network}
              itemOnClick={(val) =>
                setFormState({ ...formState, network: val })
              }
              dropdownItems={supportedNetworks}
              disabled={cryptoBeneficiaryToUpdate ? true : false}
            />
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Wallet Address
            </FormLabel>
            <Input
              type="text"
              focusBorderColor={"gray.300"}
              value={formState.address}
              name="address"
              onChange={handleInputs}
              disabled={cryptoBeneficiaryToUpdate ? true : false}
            />
          </Box>

          <Box>
            {showTagInput && (
              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Tag ID
                </FormLabel>
                <Input
                  value={formState.tag_id}
                  name="tag_id"
                  onChange={handleInputs}
                  disabled={cryptoBeneficiaryToUpdate ? true : false}
                />
              </Box>
            )}
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Description
            </FormLabel>
            <Textarea
              placeholder={"Optional"}
              focusBorderColor={"gray.300"}
              value={formState.description}
              name="description"
              onChange={handleInputs}
            />
          </Box>

          <Button
            colorScheme={"buttonPrimary"}
            onClick={openWalletPinModal}
            isLoading={isUpdateCryptoBenLoading}
          >
            Save
          </Button>
        </FormControl>
      </form>

      {showWalletPinModal && (
        <WalletPinModal
          isOpen={showWalletPinModal}
          onClose={() => setShowWalletPinModal(false)}
          onSubmit={handleSubmit}
          isLoading={isAddCryptoBeneficiaryLoading || isCheckPinLoading}
        />
      )}
    </>
  );
};

export default CryptoBeneficiaryForm;
