import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
  Button,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Select,
  Heading,
  Radio,
} from "@chakra-ui/react";
import { FiPlusCircle } from "react-icons/fi";
import BankIcon from "../../../assets/images/icons/bank.png";
import CardIcon from "../../../assets/images/icons/card.png";
import CryptoIcon from "../../../assets/images/icons/buy-crypto.png";
import {
  useFetchDepositMethodsQuery,
  useFetchSupportedCurrenciesQuery,
  useRequestDepositMutation,
} from "../../../redux/services/walletApi";
import { showError, showSuccess } from "../../../utils/Alert";
import Loader from "../../../utils/Loader";
import { usePostHog } from "posthog-js/react";

const FundWalletModal: React.FC = () => {
  const posthog = usePostHog();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);

  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [requestDeposit, { isLoading: isRequestDepositLoading }] =
    useRequestDepositMutation();
  const {
    data: depositMethods,
    isLoading: isDepositMethodsLoading,
    isSuccess: isFetchDepositMethodsSuccess,
  } = useFetchDepositMethodsQuery();
  const {
    data: fetchedSupportedCurrencies,
    isSuccess: isFetchSupportedCurrenciesSuccess,
  } = useFetchSupportedCurrenciesQuery();
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const [rate, setRate] = useState({
    rate: 1,
    symbol: "$",
  });
  const [depositFormStates, setDepositFormStates] = useState({
    currency: "",
    amount: "",
    amountToRecieve: 0,
  });

  useEffect(() => {
    let amountToRecieve = Number(depositFormStates.amount) / Number(rate.rate);
    amountToRecieve = parseFloat(amountToRecieve.toFixed(2));
    setDepositFormStates((curState) => ({ ...curState, amountToRecieve }));
  }, [rate.rate, depositFormStates.amount]);

  //   fetch supported currencies
  useEffect(() => {
    if (isFetchSupportedCurrenciesSuccess) {
      const newArray = fetchedSupportedCurrencies?.map((cur: any) => ({
        ...cur,
        title: cur?.name,
        value: cur?.code,
        name: cur?.code.toUpperCase(),
      }));

      setSupportedCurrencies(newArray);
      setDepositFormStates((state) => ({ ...state, currency: "usd" }));
    }
  }, [isFetchSupportedCurrenciesSuccess, fetchedSupportedCurrencies]);

  const handleCurrencyChange = (val: string) => {
    setDepositFormStates({ ...depositFormStates, currency: val });

    if (val === "ngn") {
      let getRate: any = supportedCurrencies.filter(
        (cur: any) => cur?.code === "ngn"
      );
      setRate({
        rate: getRate.length ? getRate[0]?.rates?.usd?.rate : 0,
        symbol: getRate.length ? getRate[0].symbol : "?",
      });
    } else if (val === "usd") {
      setRate({
        rate: 1,
        symbol: "$",
      });
    }
  };

  const returnPayMethodIcon = (code: string) => {
    if (code === "bank_transfer") {
      return BankIcon;
    } else if (code === "credit_card") {
      return CardIcon;
    } else if (code === "crypto") {
      return CryptoIcon;
    }
  };

  //   set payment methods
  useEffect(() => {
    if (isFetchDepositMethodsSuccess) {
      const availableMethods = depositMethods
        ?.filter((method: any) =>
          method?.currencies.includes(depositFormStates.currency)
        )
        ?.map((method: any) => ({
          ...method,
          isActive: false,
          icon: returnPayMethodIcon(method.code),
        }));

      setPaymentMethods(availableMethods);
    }
  }, [
    isFetchDepositMethodsSuccess,
    depositMethods,
    depositFormStates.currency,
  ]);

  const toggleActive = (clickedMethod: any) => {
    const updatedMethods = paymentMethods.map((method: any) => ({
      ...method,
      isActive: method === clickedMethod,
    }));

    setPaymentMethods(updatedMethods);
  };

  const handleBack = () => {
    setPage(page - 1);
  };

  const handleNext = () => {
    if (depositFormStates.amount === "" || depositFormStates.currency === "") {
      showError("Amount and currency are required");
      return;
    }
    setPage(page + 1);
  };

  const handleSubmit = async () => {
    const activeMethod = paymentMethods.filter((val) => val.isActive === true);

    if (!activeMethod.length) {
      showError("Select a payment method");
      return;
    }

    // process deposit request
    const fData = {
      ...depositFormStates,
      method: activeMethod[0]?.code,
    };
    await requestDeposit(fData)
      .unwrap()
      .then((resp) => {
        let checkoutUrl = "";
        posthog?.capture("wallet_funding", {
          price: depositFormStates.amount,
          currency: depositFormStates.currency,
          amountToRecieve: depositFormStates.amountToRecieve,
          method: activeMethod[0]?.code,
        });
        if (depositFormStates.currency === "ngn") {
          checkoutUrl = resp?.meta?.data?.authorization_url;
        } else {
          if (activeMethod[0]?.code === "credit_card") {
            checkoutUrl = resp?.meta?.url;
          } else if (activeMethod[0]?.code === "crypto") {
            checkoutUrl = resp?.meta?.hosted_url;
          }
        }

        window.open(checkoutUrl, "_blank");
        onClose();
        setPage(1);
        setRate({
          rate: 1,
          symbol: "$",
        });
        setDepositFormStates({
          currency: "usd",
          amount: "",
          amountToRecieve: 0,
        });
        showSuccess(
          "Request processing, you will be redirected to a checkout page"
        );
      })
      .catch((err) => {
        console.log(err);
        showError(
          err?.message ||
            err?.data?.message ||
            "An error occurred, try again later"
        );
      });
  };

  return (
    <>
      <Button
        leftIcon={<FiPlusCircle fontSize={"18px"} />}
        size={"lg"}
        fontSize={"16px"}
        fontWeight={400}
        colorScheme={"buttonPrimary"}
        rounded={"8px"}
        onClick={onOpen}
      >
        Fund wallet
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "xs", md: "lg" }}
      >
        <ModalOverlay />
        <ModalContent rounded={"16px"}>
          <ModalHeader>Fund Wallet</ModalHeader>
          <ModalCloseButton color={"brand.dangerDark"} />

          <ModalBody>
            <Loader
              isLoading={page === 2 && isDepositMethodsLoading}
              height={"100px"}
              mt={0}
            />
            {/* fund wallet form */}
            {page === 1 && (
              <FormControl display={"flex"} flexDirection={"column"} gap={5}>
                <Box>
                  <FormLabel fontWeight={"semibold"}>
                    How much would you like to deposit?
                  </FormLabel>
                  <InputGroup>
                    <NumberInput
                      min={0}
                      width={"100%"}
                      focusBorderColor={"gray.300"}
                      value={depositFormStates.amount}
                      onChange={(value) =>
                        setDepositFormStates({
                          ...depositFormStates,
                          amount: value,
                        })
                      }
                    >
                      <NumberInputField
                        placeholder="0"
                        borderRightRadius="0"
                        borderRight={"0"}
                      />
                    </NumberInput>

                    <InputRightAddon px={0}>
                      <Select
                        focusBorderColor={"gray.300"}
                        borderLeftRadius="0"
                        fontSize={"13px"}
                        borderLeft={"0"}
                        value={depositFormStates.currency}
                        onChange={(e) => handleCurrencyChange(e.target.value)}
                      >
                        {supportedCurrencies.map((val: any, idx) => (
                          <option key={`sc-${idx}`} value={val?.value}>
                            {val?.name}
                          </option>
                        ))}
                      </Select>
                    </InputRightAddon>
                  </InputGroup>

                  <Flex gap={2} alignItems={"center"} fontSize={"14px"}>
                    <Text>Rates:</Text>
                    <Text fontWeight={"semibold"}>
                      <b>$1 = {rate.symbol + rate.rate}</b>
                    </Text>
                  </Flex>
                </Box>

                <Box>
                  <FormLabel fontWeight={"semibold"}>
                    Amount to receive
                  </FormLabel>
                  <InputGroup>
                    <Input
                      value={depositFormStates.amountToRecieve}
                      focusBorderColor={"gray.300"}
                      readOnly
                    />
                    <InputRightAddon>USD</InputRightAddon>
                  </InputGroup>
                </Box>
              </FormControl>
            )}

            {/* payment option */}
            {page === 2 && (
              <Box display={"flex"} flexDirection={"column"} gap={5}>
                {paymentMethods?.map((method, idx) => (
                  <Flex
                    key={`pmI-${idx}`}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    cursor="pointer"
                    onClick={() => toggleActive(method)}
                  >
                    <Flex alignItems={"center"} gap={5} cursor={"pointer"}>
                      <Box backgroundColor={"#F3F3F3"} p={3} rounded={"full"}>
                        <img
                          src={method?.icon}
                          width={"20px"}
                          height={"20px"}
                          style={{ objectFit: "cover" }}
                          alt="card"
                        />
                      </Box>
                      <Box>
                        <Heading as={"h6"} size={"sm"}>
                          {method?.name}
                        </Heading>
                        <Text>{method?.description}</Text>
                      </Box>
                    </Flex>
                    <Radio
                      value={"1"}
                      size={"lg"}
                      isChecked={method?.isActive}
                      style={{
                        borderColor: method?.isActive ? "#232A44" : undefined,
                        backgroundColor: method?.isActive
                          ? "#232A44"
                          : undefined,
                      }}
                    />
                  </Flex>
                ))}
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            {page !== 1 && (
              <Button
                size={"lg"}
                fontSize={"16px"}
                fontWeight={400}
                backgroundColor={"#E8E8E8"}
                mr={3}
                onClick={handleBack}
              >
                Back
              </Button>
            )}

            {page === 1 && (
              <Button
                size={"lg"}
                fontSize={"16px"}
                fontWeight={400}
                colorScheme={"buttonPrimary"}
                onClick={handleNext}
              >
                Next
              </Button>
            )}

            {page === 2 && (
              <Button
                size={"lg"}
                fontSize={"16px"}
                fontWeight={400}
                colorScheme={"buttonPrimary"}
                isLoading={isRequestDepositLoading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FundWalletModal;
