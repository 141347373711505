import { Box, Button, Flex, Heading, Text, Modal, ModalOverlay, Alert, AlertIcon, AlertDescription, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, UnorderedList, ListItem, AlertTitle } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import BalanceCard from "../../components/cards/BalanceCard";
import ContactCard from "../../components/cards/ContactCard";
import FeaturedGameCard from "../../components/cards/FeaturedGameCard";
import LotteryGameCard from "../../components/cards/LotteryGameCard";
import QuestProgress from "../../components/cards/QuestProgress";
import LayoutContainerWrapper from "../../components/dashboard/LayoutContainerWrapper";
import HorizontalTabButton from "../../utils/HorizontalTabButton";
import Loader from "../../utils/Loader";
import { useFetchGamesQuery } from "../../redux/services/gamesApi";
import { GameObj, WalletTypeObj } from "../../redux/services/types";
import useTextTruncate from "../../hooks/useTextTruncate";
import { useFetchWalletBalanceQuery } from "../../redux/services/walletApi";
import { FaCheck, FaGamepad } from "react-icons/fa";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// @ts-ignore
import Slider from "react-slick";

const Home = () => {
  const [tab, setTab] = useState("Trending");
  const tabs = useMemo(
    () => ["Trending", "Top games (<$3k)", "Top games (>$3k)"],
    []
  );
  const [featuredGames, setFeaturedGames] = useState<GameObj[]>([]);
  const [gamesArr, setGamesArr] = useState<GameObj[]>([]);
  const {
    data: liveGames,
    isLoading: isLiveGamesLoading,
    isSuccess: isLiveGamesSuccess,
  } = useFetchGamesQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isLiveGamesSuccess) {
      const FGs = liveGames.filter((game) => game?.isFeatured);
      setFeaturedGames(FGs);
    }
  }, [isLiveGamesSuccess, liveGames]);

  useEffect(() => {
    const trendingTab = tabs[0];
    const l3kTab = tabs[1];
    const m3kTab = tabs[2];

    if (isLiveGamesSuccess) {
      let filteredGames = [];
      switch (tab) {
        case trendingTab:
          filteredGames = liveGames;
          break;

        case l3kTab:
          filteredGames = liveGames?.filter((game) => game?.jackpot <= 3000);
          break;

        case m3kTab:
          filteredGames = liveGames?.filter((game) => game?.jackpot > 3000);
          break;

        default:
          filteredGames = liveGames;
          break;
      }
      setGamesArr(filteredGames);
    }
  }, [isLiveGamesSuccess, tab, tabs, liveGames]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const switchTab = (val: string) => {
    setTab(val);
  };

  return (
    <>
      <Box mt="0px">
        <LayoutContainerWrapper asideChildren={<Aside />}>
          <Box mb={"24px"} minH={"200px"}>
            <Flex direction="column" gap={2} mb={4}>
              <Flex justify="center">
                <Alert
                  status="info"
                  variant="subtle"
                  borderRadius="lg"
                  maxW="container.md"
                  mx={4}
                >
                  <AlertIcon />
                  <AlertDescription>
                    <Flex align="center" gap={4}>
                      <Text>
                        🎁 Refer a friend and earn tokens to play free on any lottery!
                      </Text>
                      <Button
                        size="sm"
                        variant="solid"
                        bg="white"
                        color="brand.primary"
                        _hover={{ bg: 'gray.100' }}
                        onClick={onOpen}
                      >
                        Rules
                      </Button>
                    </Flex>
                  </AlertDescription>
                </Alert>
              </Flex>

              <Flex justify="center">
                <Alert
                  status="success"
                  variant="subtle"
                  borderRadius="lg"
                  maxW="container.md"
                  mx={4}
                >
                  <Box as={FaGamepad} color="green.500" mr={3} />
                  <Box>
                    <AlertTitle fontWeight="bold">Lost a ticket?</AlertTitle>
                    <AlertDescription>
                      Don't worry! We award free tokens on every lost ticket for the next raffle draw.
                    </AlertDescription>
                  </Box>
                </Alert>
              </Flex>
            </Flex>

            <Slider {...settings}>
              {featuredGames.map((game) => (
                <FeaturedGameCard key={game?.id} game={game} />
              ))}
            </Slider>
            <Loader isLoading={isLiveGamesLoading} height="100px" />
            {!isLiveGamesLoading && !featuredGames?.length ? (
              <Flex
                height={"232px"}
                rounded={"16px"}
                padding={["15px", "32px"]}
                width={{ base: "full", md: "80%" }}
                mx={"auto"}
                bg={"brand.lightGrey"}
                color={"brand.textMuted"}
                overflow={"hidden"}
                justify={"center"}
                align={"center"}
                direction={"column"}
              >
                <Text
                  textAlign={"center"}
                  fontSize={["16px", "20px"]}
                  fontWeight={500}
                  mb={1}
                >
                  There are no featured games right now
                </Text>
                <Text
                  textAlign={"center"}
                  fontSize={["14px", "18px"]}
                  fontWeight={400}
                >
                  but stay tuned! Exciting games will be featured soon.
                </Text>
              </Flex>
            ) : (
              ""
            )}
          </Box>

          <Box>
            <Flex justify={"space-between"} flexWrap={"wrap"}>
              <Heading fontSize={"24px"} fontWeight={500}>
                Lotteries
              </Heading>
            </Flex>
            <Box w={"full"} overflowX={"auto"} scrollBehavior={"smooth"}>
              <Flex minW={"550px"}>
                {tabs.map((value, index) => (
                  <HorizontalTabButton
                    key={`hTab-${index}`}
                    title={value.toLowerCase()}
                    onClick={() => {
                      switchTab(value);
                    }}
                    isActive={tab === value}
                  />
                ))}
              </Flex>
            </Box>

            {/* games */}
            <Box mt={"15px"}>
              <Flex
                gap={4}
                overflowX={"auto"}
                overflowY={"visible"}
                className={"hScroll"}
              >
                {gamesArr?.map((game, idx) => (
                  <LotteryGameCard key={`lt-${idx}`} game={game} />
                ))}
              </Flex>
              <Loader isLoading={isLiveGamesLoading} height="80px" />
              {!isLiveGamesLoading && !gamesArr?.length ? (
                <Text textAlign={"center"} color={"brand.textMuted"} my={"100px"}>
                  Nothing to show here
                </Text>
              ) : (
                ""
              )}
              <Flex
                mt={"30px"}
                justify={"center"}
                display={isLiveGamesLoading ? "none" : "flex"}
              >
                <Button
                  w={["60%"]}
                  as={Link}
                  to={"/lotteries"}
                  colorScheme={"buttonPrimary"}
                >
                  View more
                </Button>
              </Flex>
            </Box>
          </Box>
        </LayoutContainerWrapper>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>How to Earn Free Tokens</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text fontWeight="semibold" fontSize="lg" mb={4}>
              Follow these steps:
            </Text>
            <UnorderedList spacing={2} pl={4}>
              <ListItem>Invite your friend to create an account</ListItem>
              <ListItem>Your friend needs to fund their wallet</ListItem>
              <ListItem>They must play a game or purchase a ticket</ListItem>
              <ListItem>Voila! You'll receive your free tokens automatically</ListItem>
            </UnorderedList>
            <Box mt={4} p={4} bg="gray.50" borderRadius="md">
              <Text fontSize="sm" color="gray.600">
                Note: The referral program is subject to our terms and conditions.
                Tokens will be credited once all conditions are met.
              </Text>
              <Button
                mt={4}
                colorScheme="blue"
                as={Link}
                to="/settings/affiliate"
              >
                Get Your Affiliate Link
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const Aside = () => {
  const { formatMoney } = useTextTruncate();
  const [mainWallet, setMainWallet] = useState<null | WalletTypeObj>(null);
  const {
    data: walletBalance,
    isSuccess: isWalletBalanceSuccess,
    isLoading: isWalletBalanceLoading,
  } = useFetchWalletBalanceQuery();

  useEffect(() => {
    if (isWalletBalanceSuccess) {
      const mainWallet = walletBalance?.filter(
        (val) => val?.type?.toLowerCase() === "main"
      );

      setMainWallet(mainWallet[0]);
    }
  }, [isWalletBalanceSuccess, walletBalance]);

  return (
    <>
      <BalanceCard
        title={"Wallet Balance"}
        figure={formatMoney(mainWallet?.balance || 0)}
        isBalanceLoading={isWalletBalanceLoading}
        variant={"aside"}
      />
      <BalanceCard
        title={"Locked Balance"}
        figure={formatMoney(mainWallet?.lockedBalance || 0)}
        isBalanceLoading={isWalletBalanceLoading}
        variant={"aside"}
      />
      <QuestProgress />
      <ContactCard />
    </>
  );
};

export default Home;
