import React, { useEffect, useState } from "react";
import LayoutContainerWrapper from "../../../../../components/dashboard/LayoutContainerWrapper";
import ContactCard from "../../../../../components/cards/ContactCard";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import LeftArrow from "../../../../../assets/images/icons/arrow-left.png";
import AffiliateAndReferralClipBoard from "./AffiliateAndReferralClipBoard";
import { useFetchWalletBalanceQuery } from "../../../../../redux/services/walletApi";
import { WalletTypeObj } from "../../../../../redux/services/types";
import {
  accountApi,
  useFetchProfileQuery,
  useFetchReferralsQuery,
  useRegisterAffiliateMutation,
} from "../../../../../redux/services/accountApi";
import AffiliateAndReferralModal from "./AffiliateAndReferralModal";
import useTextTruncate from "../../../../../hooks/useTextTruncate";
import { showError, showSuccess } from "../../../../../utils/Alert";
import { useValidateRefCodeMutation } from "../../../../../redux/services/authApi";
import Loader from "../../../../../utils/Loader";
import { useDispatch } from "react-redux";
import { usePostHog } from "posthog-js/react";

const AffiliateAndReferrals = () => {
  const { formatMoney } = useTextTruncate();
  const [isAffiliateModalOpen, setIsAffiliateModalOpen] = useState(false);
  const [refWallet, setRefWallet] = useState<WalletTypeObj | null>(null);
  //   const { handleCopyClick, isCopied } = useCopyToClipBoard();
  const { data: referrals } = useFetchReferralsQuery();

  const { data: walletBalance, isSuccess: isWalletBalanceSuccess } =
    useFetchWalletBalanceQuery();

  useEffect(() => {
    if (isWalletBalanceSuccess) {
      const refWallet = walletBalance?.filter(
        (val) => val?.type?.toLowerCase() === "affiliate"
      );

      setRefWallet(refWallet[0]);
    }
  }, [isWalletBalanceSuccess, walletBalance]);

  return (
    <LayoutContainerWrapper asideChildren={<Aside />}>
      <Flex flexDirection={"column"}>
        <Flex alignItems={"center"} mb={"30px"}>
          <Link to="/settings">
            <img
              src={LeftArrow}
              alt="left direction arrow"
              width={20}
              height={20}
            />
          </Link>
          <Heading
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            m={"auto"}
            fontSize={"21px"}
            fontWeight={"semibold"}
          >
            Affiliate and referrals
          </Heading>
        </Flex>

        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          gap={"10px"}
          alignItems={"center"}
        >
          <Heading fontSize={"21px"} fontWeight={"medium"}>
            Refer a friend bonus🎉
          </Heading>

          <Text textAlign={"center"}>
            Introduce your friends to Lo3re and get <br /> bonuses and exclusive
            perks!
          </Text>

          <AffiliateAndReferralClipBoard />
        </Flex>

        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          mt={{ base: "10px", md: "20px" }}
        >
          <Flex
            flexDirection={"column"}
            gap={"20px"}
            w={{ base: "90%", md: "400px" }}
            h={"100%"}
            bgColor={"white"}
            borderRadius={"10px"}
            p={"20px"}
            boxShadow={"0px 5px 10px 0px #0000000F"}
            border={"1px solid #00000029"}
            color={"black"}
            fontSize={"14px"}
            fontWeight={"500"}
            textAlign={"center"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Flex
              bg={"#F3F3F3"}
              alignItems={"center"}
              gap={"10px"}
              borderRadius={"full"}
              px={"50px"}
              py={"20px"}
            >
              <Text fontSize={"20px"} fontWeight={"bold"}>
                {`${formatMoney(refWallet?.balance || 0)} TKN`}
              </Text>
              <Text style={{ lineHeight: "2" }}>|</Text>
              <Text fontWeight={"medium"} fontSize={"14px"}>
                Referral Bonus
              </Text>
            </Flex>

            <Flex
              bg={"#F3F3F3"}
              alignItems={"center"}
              gap={"10px"}
              borderRadius={"full"}
              px={"30px"}
              py={"20px"}
            >
              <Text fontSize={"20px"} fontWeight={"bold"}>
                {referrals?.length}
              </Text>
              <Text style={{ lineHeight: "2" }}>|</Text>
              <Text fontWeight={"medium"} fontSize={"14px"}>
                Friends referred
              </Text>
            </Flex>

            <Flex direction={"column"} gap={1}>
              <Button
                size={"lg"}
                fontSize={"16px"}
                fontWeight={400}
                colorScheme={"buttonPrimary"}
                w={"100px"}
                onClick={() => setIsAffiliateModalOpen(true)}
              >
                View list
              </Button>
            </Flex>

            {isAffiliateModalOpen && (
              <AffiliateAndReferralModal
                isOpen={isAffiliateModalOpen}
                onClose={() => setIsAffiliateModalOpen(false)}
                refs={referrals}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </LayoutContainerWrapper>
  );
};

const Aside = () => {
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const [myRefCode, setMyRefCode] = useState("");
  const [upliner, setUpliner] = useState<
    { email?: string; photoUrl?: string; username?: string } | undefined
  >(undefined);
  const {
    data: user,
    isLoading: isUserLoading,
    isSuccess: isFetchUserSuccess,
  } = useFetchProfileQuery();
  const [registerAffiliate, { isLoading: isRegisterAffiliateLoading }] =
    useRegisterAffiliateMutation();
  const [validateAffiliate, { isLoading: isValidateAffiliateLoading }] =
    useValidateRefCodeMutation();
  const [fetchAffiliate, { isLoading: isfetchAffiliateLoading }] =
    useValidateRefCodeMutation();

  useEffect(() => {
    if (isFetchUserSuccess && user?.user?.referrer_code) {
      fetchAffiliate({ referral_code: user?.user?.referrer_code })
        .unwrap()
        .then((resp) => {
          console.log(resp);
          if (resp?.success) {
            if (resp?.data?.length) {
              setUpliner(resp?.data[0]);
            }
          }
        });
    }
  }, [isFetchUserSuccess, user, fetchAffiliate]);

  const registerPromoCode = async () => {
    let isValidateErr = true;
    if (!myRefCode) {
      showError("Enter referrer code");
      return;
    }

    await validateAffiliate({ referral_code: myRefCode })
      .unwrap()
      .then((resp) => {
        console.log(resp);
        if (resp?.success) {
          isValidateErr = false;
        }
      })
      .catch((err) => {
        console.log(err);
        showError(
          err?.message ||
            err?.data?.message ||
            "Could not validate referrer code"
        );
      });

    if (!isValidateErr) {
      registerAffiliate({ referrer_code: myRefCode })
        .unwrap()
        .then((resp) => {
          if (resp?.success) {
            showSuccess("success");
            posthog?.capture("upliner_registered");
            // onReferrerModalClose();
            dispatch(accountApi.util.invalidateTags(["profile"]));
          } else {
            showError("something went wrong, try again later");
          }
        })
        .catch((err) => {
          console.log(err);
          showError(err?.data?.message || err?.message || "An error occurred");
        });
    }
  };

  return (
    <>
      <Box
        padding={"14px"}
        bg={"#fff"}
        borderRadius={"16px"}
        boxShadow={"0px 5px 10px 0px rgba(0, 0, 0, 0.06)"}
      >
        <Text fontSize={"14px"} fontWeight={500} mb={"12px"}>
          My upliner
        </Text>

        <Loader
          isLoading={isfetchAffiliateLoading || isUserLoading}
          height={"100px"}
          mt={0}
        />
        {upliner ? (
          <Flex justify={"center"} align={"center"} direction={"column"}>
            <Avatar src={upliner?.photoUrl} name={upliner?.username} />
            <Text mt={1} fontWeight={500}>
              {upliner?.username}
            </Text>
          </Flex>
        ) : (
          ""
        )}

        {!isfetchAffiliateLoading && !isUserLoading && upliner === undefined ? (
          <>
            <FormControl isRequired>
              <Input
                focusBorderColor={"gray.300"}
                placeholder={"Enter your referrer code"}
                value={myRefCode}
                onChange={({ target }) => setMyRefCode(target.value)}
              />
            </FormControl>
            <Button
              mt={2}
              colorScheme={"buttonPrimary"}
              w={"100%"}
              size={"sm"}
              onClick={registerPromoCode}
              isLoading={
                isRegisterAffiliateLoading || isValidateAffiliateLoading
              }
            >
              Submit
            </Button>
          </>
        ) : (
          ""
        )}
      </Box>
      <ContactCard />
    </>
  );
};

export default AffiliateAndReferrals;
