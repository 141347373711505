import React, { useRef, useState } from "react";
import { Box, Flex, Input, Text } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";
import { GoCopy } from "react-icons/go";
import { useFetchProfileQuery } from "../../../../../redux/services/accountApi";
import { usePostHog } from "posthog-js/react";

const AffiliateAndReferralClipBoard = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputRefII = useRef<HTMLInputElement | null>(null);
  const posthog = usePostHog();
  const [hasCopied, setHasCopied] = useState(false);
  const [hasCopiedII, setHasCopiedII] = useState(false);
  const { data: user } = useFetchProfileQuery();

  const handleCopy = () => {
    // Optional chaining to safely access value
    const inputValue = inputRef.current?.value;

    if (inputValue) {
      // Copy the input value
      navigator.clipboard
        .writeText(inputValue)
        .then(() => {
          posthog?.capture("referral_link_copied");
          setHasCopied(true);
        })
        .catch((error) => {
          console.error("Unable to copy text: ", error);
        });

      // Reset hasCopied after a short delay
      setTimeout(() => {
        setHasCopied(false);
      }, 1000);
    }
  };

  const handleCopyII = () => {
    // Optional chaining to safely access value
    const inputValue = inputRefII.current?.value;

    if (inputValue) {
      // Copy the input value
      navigator.clipboard
        .writeText(inputValue)
        .then(() => {
          posthog?.capture("referral_link_copied");
          setHasCopiedII(true);
        })
        .catch((error) => {
          console.error("Unable to copy text: ", error);
        });

      // Reset hasCopied after a short delay
      setTimeout(() => {
        setHasCopiedII(false);
      }, 1000);
    }
  };

  return (
    <>
      <Box>
        <Text>Referral link</Text>
        <Flex flexDirection={"column"} mb={2} position={"relative"}>
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
          >
            <Input
              ref={inputRef}
              focusBorderColor={"gray.300"}
              value={
                `https://player.lo3re.com/signup/${user?.user?.referral_code}` ||
                "-"
              }
              readOnly={true}
              fontSize={"14px"}
              width={{ base: "100%", md: "400px" }}
            />
            <Box
              position={"absolute"}
              top={"13px"}
              right={{ base: "10px", md: "5px" }}
              color={"#868686"}
              cursor={"pointer"}
              onClick={handleCopy}
            >
              <Box fontSize="md" fontWeight={"semibold"}>
                {hasCopied ? <FaCheck /> : <GoCopy />}
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box>
        <Text>Promo code</Text>
        <Flex flexDirection={"column"} mb={2} position={"relative"}>
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
          >
            <Input
              ref={inputRefII}
              focusBorderColor={"gray.300"}
              value={user?.user?.referral_code || "-"}
              readOnly={true}
              fontSize={"14px"}
              width={{ base: "100%", md: "400px" }}
            />
            <Box
              position={"absolute"}
              top={"13px"}
              right={{ base: "10px", md: "5px" }}
              color={"#868686"}
              cursor={"pointer"}
              onClick={handleCopyII}
            >
              <Box fontSize="md" fontWeight={"semibold"}>
                {hasCopiedII ? <FaCheck /> : <GoCopy />}
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default AffiliateAndReferralClipBoard;
