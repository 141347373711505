import { getAnalytics, logEvent, Analytics } from "firebase/analytics";
import { useEffect, useState } from "react";
import { auth } from "../firebase";

export const useAnalytics = () => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  useEffect(() => {
    // Initialize analytics
    setAnalytics(getAnalytics());
  }, []);

  const logLoginEvent = (method: string) => {
    console.log('login event called', method);
    if (analytics) {
      logEvent(analytics, 'login', {
        method: method,
        user_id: auth.currentUser?.uid
      });
    }
  };

  const logSignupEvent = (method: string) => {
    if (analytics) {
      logEvent(analytics, 'sign_up', {
        method: method,
        user_id: auth.currentUser?.uid
      });
    }
  };

  const logWalletFunded = (amount: number, currency: string = 'USD') => {
    if (analytics) {
      logEvent(analytics, 'wallet_funded', {
        value: amount,
        currency,
        user_id: auth.currentUser?.uid
      });
    }
  };

  const logTicketPurchased = (amount: number, currency: string = 'USD') => {
    if (analytics) {
      logEvent(analytics, 'ticket_purchased', {
        value: amount,
        currency,
        user_id: auth.currentUser?.uid
      });
    }
  };

  return {
    logLoginEvent,
    logSignupEvent,
    logWalletFunded,
    logTicketPurchased
  };
}; 