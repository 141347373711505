import { Spinner } from "@chakra-ui/react";
import { lazy, Suspense } from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import Layout from "../components/dashboard/Layout";
import ErrorPage from "../pages/ErrorPage";
import PlayerHome from "../pages/player/Home";
import History from "../pages/player/History";
import Lotteries from "../pages/player/Lotteries";
import Wallet from "../pages/player/Wallet";
import PreLogin from "../pages/auth/PreLogin";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetLink from "../pages/auth/ResetLink";
import ResetPassword from "../pages/auth/ResetPassword";
import Signup from "../pages/auth/Signup";
import ManageBeneficiaries from "../pages/player/ManageBeneficiaries";
import HandleEmailActions from "../pages/auth/HandleEmailActions";
import ActivateWalletPin from "../pages/auth/ActivateWalletPin";
import Verify2FA from "../pages/auth/Verify2FA";
import CompleteProfile from "../pages/auth/CompleteProfile";
import Settings from "../pages/player/settings/Settings";
import EditProfile from "../pages/player/settings/profile/EditProfile";
import KycVerification from "../pages/player/settings/profile/KYCVerification";
import AffiliateAndReferrals from "../pages/player/settings/profile/affiliateAndReferrals/AffiliateAndReferrals";
import WalletPin from "../pages/player/settings/security/WalletPin";
import TwoFactorAuth from "../pages/player/settings/security/TwoFactorAuth";
import PasswordReset from "../pages/player/settings/security/PasswordReset";
import Notification from "../pages/player/Notification";
import ResetDone from "../pages/auth/ResetDone";
// import Login from "../pages/auth/Login";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner color={"brand.primary"} />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const Routes = () => {
  let router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Outlet />
        </>
      ),
      children: [
        { path: "", element: <PreLogin /> },
        { path: "/signup", element: <Signup /> },
        { path: "/signup/:refCode", element: <Signup /> },
        { path: "/link-sent", element: <ResetLink /> },
        // { path: "/login", element: <Login /> },
        { path: "/forgot-password", element: <ForgotPassword /> },
        // { path: "/reset-link", element: <ResetLink /> },
        { path: "/createPassword", element: <ResetPassword /> },
        { path: "/reset-done", element: <ResetDone /> },
        { path: "/activate-wallet-pin", element: <ActivateWalletPin /> },
        { path: "/verify-2fa", element: <Verify2FA /> },
        { path: "/complete-profile", element: <CompleteProfile /> },
        { path: "/verify", element: <HandleEmailActions /> },
      ],
      errorElement: <ErrorPage />,
    },
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        { path: "dashboard", element: <PlayerHome /> },
        {
          path: "lotteries",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Lotteries />,
            },
            {
              path: "my-tickets",
              element: <MyTickets />,
            },
            {
              path: "view-game/:gid",
              element: <ViewGame />,
            },
          ],
        },
        { path: "security", element: <Wallet /> },
        { path: "history", element: <History /> },
        { path: "beneficiaries", element: <ManageBeneficiaries /> },
        {
          path: "settings",
          element: <Outlet />,
          children: [
            { path: "", element: <Settings /> },
            { path: "edit-profile", element: <EditProfile /> },
            { path: "kyc", element: <KycVerification /> },
            { path: "affiliate", element: <AffiliateAndReferrals /> },
            { path: "security-pin", element: <WalletPin /> },
            { path: "2fa", element: <TwoFactorAuth /> },
            { path: "password-reset", element: <PasswordReset /> },
          ],
        },
        { path: "notification", element: <Notification /> },
      ],
    },
  ]);

  return router;
};

const MyTickets = Loadable(lazy(() => import("../pages/player/MyTickets")));
const ViewGame = Loadable(lazy(() => import("../pages/player/ViewGame")));

export default Routes;
