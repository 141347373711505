import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Fonts from "./Fonts";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles/GeneralStyles.css";
import "./assets/styles/fonts/fonts.css";

import theme from "./theme/index";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <Fonts />
          <App />
        </Provider>
      </ChakraProvider>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
